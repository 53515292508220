import pages from '@/features/pages';

export default [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackPrefetch: true */ '@/views/Index'),
    meta: {
      title: '',
    },
  },
  {
    path: '/c_address',
    name: 'Address',
    component: () => import(/* webpackPrefetch: true */ '@/views/Address'),
    meta: {
      title: '搜索',
    },
  },
  {
    path: '/c_collect/add',
    name: 'CollectAdd',
    component: () => import('@/views/collect/add'),
    meta: {
      title: '收藏课程',
    },
  },
  {
    path: '/c_collect',
    name: 'Collect',
    component: () => import('@/views/collect'),
    meta: {
      title: '收藏课程',
    },
  },
  // {
  //   path: '/c_lession/:id?/:type?',
  //   name: 'Lession',
  //   component: () => import('@/views/collect/Lession'),
  //   meta: {
  //     title: '课程添加',
  //   },
  // },
  {
    path: '/c_profit',
    name: 'Profit',
    component: () => import('@/views/profit/Profit'),
    meta: {
      title: '加油站',
    },
  },
  {
    //兼容老链接
    path: '/myInfo',
    redirect: '/c_user',
  },
  {
    path: '/c_user',
    name: 'User',
    component: () => import('@/views/my/User'),
    meta: {
      title: '个人中心',
    },
  },
  {
    path: '/c_curricu',
    name: 'Curricu',
    component: () => import('@/views/course/Curriculum'),
    meta: {
      title: '申请免费观看',
    },
  },
  {
    path: '/c_details/:id/:cid?',
    name: 'Details',
    component: () =>
      import(/* webpackPrefetch: true */ '@/views/course/Details'),
    meta: {
      // title: '掌上考场',
    },
  },
  //课程兼容学员端
  {
    path: '/Video/:id/:lid?',
    redirect: (to) =>
      `/c_details/${to.params.id || ''}${
        to.params.lid ? '/' + to.params.lid : ''
      }`,
  },
  //课程兼容新前端
  {
    path: '/lessonInfo',
    redirect: (to) => `/c_details/${to.query.nmiids || to.query.id}`,
  },
  {
    path: '/c_share/:id/:cid?',
    name: 'Share',
    component: () => import('@/views/tools/share/Share'),
    meta: {
      title: '长按图片或发送给好友',
    },
  },
  {
    path: '/c_staff',
    name: 'Staff',
    component: () => import('@/views/profit/Staff'),
    meta: {
      title: '下级代理',
    },
  },
  {
    path: '/c_receive_today',
    name: 'Receive_today',
    component: () => import('@/views/profit/Receive_today'),
    meta: {
      title: '今日收益',
    },
  },
  {
    path: '/c_receive_total',
    name: 'Receive_total',
    component: () => import('@/views/profit/Receive_total'),
    meta: {
      title: '累计收益',
    },
  },
  {
    path: '/c_order',
    name: 'Order',
    component: () => import('@/views/profit/Order'),
    meta: {
      title: '提现记录',
    },
  },
  {
    path: '/c_cash',
    name: 'Cash',
    component: () => import('@/views/profit/Cash'),
    meta: {
      title: '提现',
    },
  },
  {
    path: '/c_bind',
    name: 'Bind',
    component: () => import('@/views/Bind'),
    meta: {
      title: '招募链接',
    },
  },
  {
    path: '/c_backregister',
    redirect: `/c_bind`,
  },
  {
    path: '/c_proving',
    name: 'Proving',
    component: () => import('@/views/my/Proving'),
    meta: {
      title: '个人认证',
    },
  },
  {
    path: '/c_bindphone',
    name: 'BindPhone',
    component: () => import('@/views/my/Bind_phone'),
    meta: {
      title: '绑定手机',
    },
  },
  {
    path: '/c_modifyphone',
    name: 'ModifyPhone',
    component: () => import('@/views/my/Modify_phone'),
    meta: {
      title: '修改手机号码',
    },
  },
  {
    path: '/c_modifypassw',
    name: 'ModifyPassw',
    component: () => import('@/views/my/Modify_passw'),
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '/c_code',
    name: 'Code',
    component: () => import('@/views/my/Code'),
    meta: {
      title: '我的二维码',
    },
  },
  {
    path: '/c_codepop',
    name: 'Codepop',
    component: () => import('@/views/my/Codepop'),
    meta: {
      title: '二维码详情',
    },
  },
  {
    path: '/c_invite',
    name: 'Invite',
    component: () => import('@/views/tools/share/Invite'),
    meta: {
      title: '邀请教练',
    },
  },
  {
    path: '/c_shareall',
    name: 'Shareall',
    component: () => import('@/views/tools/share/Shareall'),
    meta: {
      title: '一键分享',
    },
  },
  {
    path: '/c_linelist',
    name: 'Linelist',
    component: () => import('@/views/circuitDiagram/Linelist'),
    meta: {
      title: '线路图列表',
    },
  },
  {
    path: '/c_LinelistSwitchCoach',
    name: 'LinelistSwitchCoach',
    component: () => import('@/views/circuitDiagram/LinelistSwitchCoach'),
    meta: {
      title: '线路图教练切换',
    },
  },
  {
    path: '/c_linecard',
    name: 'Linecard',
    component: () => import('@/views/circuitDiagram/Linecard'),
    meta: {
      title: '线路图下载',
    },
  },
  {
    path: '/c_recruit',
    name: 'Recruit',
    component: () => import('@/views/tools/Recruit'),
    meta: {
      title: '教练工具',
    },
  },
  {
    path: '/c_enrollInfo',
    name: 'EnrollInfo',
    component: () => import('@/views/tools/EnrollInfo'),
    meta: {
      title: '招生海报信息编辑',
    },
  },
  {
    path: '/c_advertcard',
    name: 'Advertcard',
    component: () => import('@/views/tools/share/Advertcard'),
    meta: {
      title: '招生海报下载',
    },
  },
  {
    path: '/c_comment/:id',
    name: 'Comment',
    component: () => import('@/views/course/Details_commenting'),
    meta: {
      title: '评论',
    },
  },
  {
    path: '/c_star',
    name: 'Star',
    component: () => import('@/views/Star'),
    meta: {
      title: '等级说明',
    },
  },
  {
    path: '/c_post_card',
    name: 'Postcard',
    component: () => import('@/views/tools/share/Postcard'),
    meta: {
      title: '招生海报',
    },
  },
  {
    path: '/c_audio',
    name: 'Audio',
    component: () => import('@/views/my/Audio'),
    meta: {
      title: '推送消息设置',
    },
  },
  {
    path: '/c_proveinfo',
    name: 'Proveinfo',
    component: () => import('@/views/tools/share/Proveinfo'),
    meta: {
      title: '奖状编辑',
    },
  },
  {
    path: '/c_prove',
    name: 'Prove',
    component: () => import('@/views/tools/share/Prove'),
    meta: {
      title: '颁发奖状',
    },
  },
  {
    path: '/c_make',
    name: 'Make',
    component: () => import('@/views/tools/make/Make'),
    meta: {
      title: '车辆发布',
    },
  },
  {
    path: '/c_makelist',
    name: 'Makelist',
    component: () => import('@/views/tools/make/Makelist'),
    meta: {
      title: '我发布的车辆',
    },
  },
  {
    path: '/c_makeinfo',
    name: 'Makeinfo',
    component: () => import('@/views/tools/make/Makeinfo'),
    meta: {
      title: '车辆转让',
    },
  },
  {
    path: '/c_makedetails',
    name: 'Makedetails',
    component: () => import('@/views/tools/make/Makedetails'),
    meta: {
      title: '车辆详情',
    },
  },
  {
    path: '/c_recruitindex',
    name: 'Recruitindex',
    component: () => import('@/views/tools/recruit/Recruitindex'),
    meta: {
      title: '招聘求职',
    },
  },
  {
    path: '/c_recruitinfo',
    name: 'Recruitinfo',
    component: () => import('@/views/tools/recruit/Recruitinfo'),
    meta: {
      title: '发布招聘',
    },
  },
  {
    path: '/c_recruitlist',
    name: 'Recruitlist',
    component: () => import('@/views/tools/recruit/Recruitlist'),
    meta: {
      title: '我要招人',
    },
  },
  {
    path: '/c_recruitdetails',
    name: 'Recruitdetails',
    component: () => import('@/views/tools/recruit/Recruitdetails'),
    meta: {
      title: '招聘详情',
    },
  },
  {
    path: '/c_trainindex',
    name: 'Train',
    component: () => import('@/views/tools/train/Trainindex'),
    meta: {
      title: '委托培训',
    },
  },
  {
    path: '/c_traininfo',
    name: 'Traininfo',
    component: () => import('@/views/tools/train/Traininfo'),
    meta: {
      title: '委托发布',
    },
  },
  {
    path: '/c_traindetails',
    name: 'Traindetails',
    component: () => import('@/views/tools/train/Traindetails'),
    meta: {
      title: '委托详情',
    },
  },
  {
    path: '/c_trainlist',
    name: 'Trainlist',
    component: () => import('@/views/tools/train/Trainlist'),
    meta: {
      title: '我的委托',
    },
  },
  {
    path: '/c_avatarType',
    name: 'avatarType',
    component: () => import('@/views/tools/coach/avatarType'),
    meta: {
      title: '招生头像',
    },
  },
  {
    path: '/c_templist',
    name: 'tempList',
    component: () => import('@/views/tools/coach/tempList'),
    meta: {
      title: '招生头像',
    },
  },
  {
    path: '/c_avatarmaking',
    name: 'avatarMaking',
    component: () => import('@/views/tools/coach/avatarMaking'),
    meta: {
      title: '招生头像',
    },
  },
  {
    path: '/c_makingcom',
    name: 'makingCom',
    component: () => import('@/views/tools/coach/makingCom'),
    meta: {
      title: '招生头像',
    },
  },
  {
    path: '/c_adscar',
    name: 'Adscar',
    component: () => import('@/views/my/Adscar'),
    meta: {
      title: '广告',
    },
  },
  {
    path: '/c_poster',
    name: 'Poster',
    component: () => import('@/views/my/Poster'),
    meta: {
      title: '广告详情',
    },
  },
  {
    path: '/c_posterlist',
    name: 'Posterlist',
    component: () => import('@/views/my/Posterlist'),
    meta: {
      title: '广告列表',
    },
  },
  {
    path: '/c_process',
    name: 'Process',
    component: () => import('@/views/course/Process'),
    meta: {
      title: '审核进程',
    },
  },
  /************ 积分 ********************************************/
  {
    path: '/c_active',
    name: 'Active',
    component: () => import('@/views/active/active'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/c_add',
    name: 'Add',
    component: () => import('@/views/active/address'),
    meta: {
      title: '收货地址',
    },
  },
  {
    path: '/c_exchange',
    name: 'Exchange',
    component: () => import('@/views/active/exchange'),
    meta: {
      title: '积分商城',
    },
  },
  {
    path: '/c_goods',
    name: 'Goods',
    component: () => import('@/views/active/goods'),
    meta: {
      title: '商品详情',
    },
  },
  {
    path: '/c_infor',
    name: 'infor',
    component: () => import('@/views/active/infor'),
    meta: {
      title: '已兑换商品',
    },
  },
  {
    path: '/c_integ',
    name: 'integ',
    component: () => import('@/views/active/integral'),
    meta: {
      title: '我的积分',
    },
  },
  {
    path: '/c_give',
    name: 'give',
    component: () => import('@/views/active/give'),
    meta: {
      title: '安装详情',
    },
  },
  {
    path: '/emsDetail',
    name: 'emsDetail',
    component: () => import('@/views/tools/ems/detail'),
    meta: {
      title: '获取免费线路图',
    },
  },
  {
    path: '/emsLst',
    name: 'emsLst',
    component: () => import('@/views/tools/ems/lists'),
    meta: {
      title: '线路图实物图纸申领记录',
    },
  },
  {
    path: '/c_givelist',
    name: 'giveList',
    component: () => import('@/views/active/givelist'),
    meta: {
      title: '安装列表',
    },
  },
  {
    path: '/complain/index',
    name: 'com_index',
    component: () => import('@/views/my/complain/index'),
    meta: {
      title: '投诉列表',
    },
  },
  {
    path: '/matter',
    name: 'matter',
    component: () => import('@/views/my/Matter'),
    meta: {
      title: '审核超时事项',
    },
  },
  {
    path: '/complain/details',
    name: 'com_details',
    component: () => import('@/views/my/complain/details'),
    meta: {
      title: '投诉详情',
    },
  },
  {
    path: '/prove/permit',
    name: 'Permit',
    component: () => import('@/views/my/prove/permit'),
    meta: {
      title: '上传营业执照',
    },
  },
  {
    path: '/billPush',
    name: 'bill_push',
    component: () => import('@/views/my/billPush'),
    meta: {
      title: '账单推送',
    },
  },
  {
    path: '/punchIn',
    name: 'punchIn',
    component: () => import('@/views/my/punchIn'),
    meta: {
      title: '打卡',
    },
  },
  {
    path: '/spreadSetting',
    name: 'spreadSetting',
    component: () => import('@/views/my/spreadSetting'),
    meta: {
      title: '扫描身份切换',
    },
  },
  {
    path: '/spreadLst',
    name: 'spreadLst',
    component: () => import('@/views/my/spreadLst'),
    meta: {
      // title: '扫描身份切换',
    },
  },
  {
    path: '/spCode',
    name: 'spCode',
    component: () => import('@/views/my/spCode'),
    meta: {
      // title: '测试链接',
    },
  },

  // 兼容新前端
  {
    // 积分商品详情
    path: '/itgDetail',
    redirect: '/c_goods',
  },

  ...pages,
  {
    path: '/:catchAll*',
    redirect: '/',
  },
];
